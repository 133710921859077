var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ds-panel ds-panel--space_2xl"},[_c('button',{staticClass:"close-popup mdi mdi-close",on:{"click":function($event){$event.preventDefault();return _vm.$emit("close")}}}),_c('div',{staticClass:"ds-panel__element ds-panel__element--offset_full"},[_c('h2',{staticClass:"ds-caption ds-caption--size_lg ds-caption--medium ds-caption--appearance_center-xl main-title tt-space_mb tt-space_mb--5"},[_vm._v(_vm._s(_vm._('Выбор подарка')))])]),_c('paginated-resource-controller',{ref:"controller",attrs:{"resource":_vm.$options.retrieveGiftsList,"parameters":_vm.parameters,"changeParameters":_vm.changeParameters},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pagination = ref.pagination;
var changePagination = ref.changePagination;
var changeFilters = ref.changeFilters;
var filters = ref.filters;
var receive = ref.receive;
var result = ref.result;
var loading = ref.loading;
return [_c('div',[(loading)?_c('div',{staticClass:"relative",staticStyle:{"min-height":"200px"}},[_c('ui-loader')],1):_vm._e(),_c('div',{staticClass:"cart-wrapper"},[_vm._l((result && result.items),function(item,index){return (!!(result && result.items && result.items.length))?[_c('div',{staticClass:"gift-wrap",on:{"click":function($event){$event.preventDefault();return _vm.selectCurrent(item.id)}}},[_c('product-base-view',{attrs:{"product":{ "element": item, "quantity": _vm.count },"price":_vm._("Подарок"),"disabled":true,"is-gift":true},scopedSlots:_vm._u([{key:"sale",fn:function(){return [_c('counter',{attrs:{"amount":_vm.count,"disable":true}})]},proxy:true},{key:"mob-sale",fn:function(){return [_c('div',{staticClass:"g-cell g-cols g-cols--12"},[_c('counter',{attrs:{"amount":_vm.count,"disable":true}})],1)]},proxy:true},{key:"pricetips",fn:function(){return [_c('p')]},proxy:true},{key:"info",fn:function(){return [_c('p')]},proxy:true},{key:"sum",fn:function(ref){
var price = ref.price;
return [_c('p')]}},{key:"counter",fn:function(){return [_c('p')]},proxy:true},{key:"price",fn:function(ref){
var price = ref.price;
return [_c('p',{staticClass:"ds-caption ds-caption--size_sm ds-caption--semibold ds-caption--appearance_right ds-caption--color_accent"},[_vm._v(_vm._s(_vm._('Подарок')))])]}},{key:"desktop-price",fn:function(ref){
var price = ref.price;
return [_c('p',{staticClass:"ds-caption ds-caption--size_sm ds-caption--semibold ds-caption--appearance_right ds-caption--color_accent"},[_vm._v(_vm._s(_vm._('Подарок')))])]}},{key:"price-tips",fn:function(){return undefined},proxy:true}],null,true)})],1)]:_vm._e()})],2),_c('ui-limit-pagination',{staticClass:"ds-panel--space_none-xl tt-space_mt tt-space_mt--5",attrs:{"pagination":pagination,"need-add-btns":false},on:{"showAll":function($event){return _vm.handlePaginate($event, changePagination, pagination)},"input":function($event){return _vm.handlePaginate($event, changePagination, pagination)},"hook:created":function($event){return changeFilters(_vm.parameters)}}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }